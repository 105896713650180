import React from 'react';
import { Helmet } from 'react-helmet';

import { MembershipFormProvider } from '$store/membershipFormContext';

import MemberForm from '$components/memberForm';

import Section from '$ui/section';
import Heading from '$ui/heading';
import Anchor from '$ui/anchor';

import { useUser } from '$hooks/auth';

import heroImg from '$images/membership/hero.png';
import illustration1Img from '$images/membership/membership.png';
import illustration2Img from '$images/membership/entitled.png';
import illustration3Img from '$images/membership/termision.png';

const MembershipPage = () => {
  const { user } = useUser();

  return (
    <>
      <Helmet>
        <title>TheCRO &mdash; Membership</title>
      </Helmet>

      {user?.role !== 'member' ? (
        <>
          <header className='min-h-[50vh] grid grid-cols-2 gap-x-20 gap-y-10 px-20 pt-28 pb-10 tab-port:grid-cols-none tab-port:justify-items-center phone:px-6'>
            <img
              src={heroImg}
              alt='HERO'
              className='self-center tab-port:h-[50vw]'
            />

            <div className='flex flex-col justify-around tab-port:space-y-10 tab-port:text-center'>
              <Heading>Become a member</Heading>

              <p>
                Any person of India who is working under any category in Central
                or state government or in semi-government or in private firm,
                all farmers, all public community and all those persons who are
                somehow consumer can be the
              </p>

              <p>
                Member of UPBHOKTA ADHIKAR SANGATHAN (Consumer Rights
                Organisation). Upbhokta Adhikar Sangathan whose work area is
                whole India. Any person who is citizen of India can be member of
                this organisation.
              </p>
            </div>
          </header>
          <main>
            <Section id='section-membership'>
              <MembershipFormProvider>
                <MemberForm />
              </MembershipFormProvider>
            </Section>

            <Section>
              <Heading>Some terms and conditions</Heading>

              <div className='grid grid-cols-2 gap-10 mt-20 items-center justify-items-center px-32 tab-land:px-10 tab-port:grid-cols-none tab-port:gap-y-16 tab-port:justify-items-start phone:px-6'>
                <div>
                  <Heading variant='tertiary-left'>
                    To become member of CRO India you have following ability
                  </Heading>
                  <ol className='mt-6 space-y-2'>
                    <li>1. Person should not be less than 18 years of age.</li>
                    <li>2. Person should be a citizen of India.</li>
                    <li>
                      3. Person should pledge to follow the rules of the
                      organization.
                    </li>
                    <li>4. Person should be of Good character.</li>
                  </ol>
                </div>
                <img
                  src={illustration1Img}
                  alt='ILLUSTRAION'
                  className='h-60 tab-port:hidden'
                />

                <img
                  src={illustration2Img}
                  alt='ILLUSTRAION'
                  className='h-60 tab-port:hidden'
                />
                <div>
                  <Heading variant='tertiary-left'>
                    By becoming a member of CRO India, you are entitled to
                  </Heading>

                  <ol className='mt-6 space-y-2'>
                    <li>1. You are able to work freely.</li>
                    <li>
                      2. Handling of your complaints without charges (free)
                    </li>
                    <li>
                      3. An Identity card for Life Members on production of
                      stamp size photo.
                    </li>
                    <li>4. Free Guidance and advice.</li>
                  </ol>
                </div>

              </div>
            </Section>
          </main>
        </>
      ) : (
        <main className='py-32 text-center space-y-10'>
          <Heading>You are already a member!</Heading>
          <Anchor variant='filled' href='/me/dashboard'>
            Go to dashboard
          </Anchor>
        </main>
      )}
    </>
  );
};

export default MembershipPage;
