import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Stepper from './stepper';
import Packages from './packages';
import Terms from './terms';
import DetailsForm from './form';
import Success from './success';

import useMembershipForm from '$store/membershipFormContext';

const getView = step => {
  if (step === 0) return <Packages />;

  if (step === 1) return <Terms />;

  if (step === 2) return <DetailsForm />;

  if (step === 3) return <Success />;
};

const MemberForm = () => {
  const { step } = useMembershipForm();

  useEffect(() => navigate('#section-membership'), [step]);

  return (
    <>
      <Stepper
        steps={[
          <>choose a plan</>,
          <>terms &amp; conditions</>,
          <>fill up the form</>,
          <>make payment</>
        ]}
      />

      <div className='pt-16 max-w-7xl mx-auto'>{getView(step)}</div>
    </>
  );
};

export default MemberForm;
