import React, { createContext, useContext, useState } from 'react';

const MembershipFormContext = createContext();

const MembershipFormProvider = ({ children }) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    membershipType: 'single'
  });

  return (
    <MembershipFormContext.Provider value={{ data, setData, step, setStep }}>
      {children}
    </MembershipFormContext.Provider>
  );
};

const useMembershipForm = () => useContext(MembershipFormContext);
export { MembershipFormContext, MembershipFormProvider };
export default useMembershipForm;
