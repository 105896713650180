import React from 'react';

import Package from '$components/package';

const PackageForm = () => (
  <div className='flex justify-center children:max-w-sm'>
    <Package
      inputId='patron'
      identifier='patron'
      features={[
        'You will be provided an Identity card.',
        'You can file no. of complaints in a year.',
        'you can become a consumer activist by participating in cro activities',
        'You will be aware of New Rules and regulations related to consumers.',
        'You are entitled for training programs conducted by Organisation with various departments.',
        'Informative News letter will be sent periodically.'
      ]}
      price={1000}
    />
  </div>
);

export default PackageForm;
