import React, { useCallback, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Heading from '$ui/heading';
import Input from '$ui/input';
import Button from '$ui/button';
import PincodeField from '$ui/pincodeField';

import { getMembershipCheckOut } from '$utils/queryFunctions';
import loadRazorpay from '$utils/loadRazorpay';

import { useUser } from '$hooks/auth';

import logoImg from '$images/logo.png';
import { navigate } from 'gatsby';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const memberSchema = Yup.object().shape({
  fullName: Yup.string().trim().required('Please provide your name'),

  phone: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Please provide your phone number'),

  email: Yup.string()
    .email('Invalid email')
    .required('Please provide your email address'),

  dateOfBirth: Yup.date()
    .min('1946-12-31T18:30:00.000Z', 'Please provide correct date of birth')
    .max(new Date().toISOString(), 'Please provide correct date of birth')
    .required('Please provide your date of birth'),

  password: Yup.string()
    .trim()
    .min(8, 'Password is too short')
    .required('Please provide your password'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'The two passwords do not match')
    .required('Please confirm your password'),

  pincode: Yup.string()
    .matches(/^[0-9]+$/, 'Pincode can contain only digits')
    .min(6, 'Pincode must be of exactly 6 digits')
    .max(6, 'Pincode must be of exactly 6 digits')
    .required('Please provide your pincode'),

  state: Yup.string().required('Please provide the state in which you live'),

  city: Yup.string().required('Please provide the city in which you live'),

  address: Yup.string()
    .trim()
    .required('Please provide your current residential address')
});

const initialValues = {
  fullName: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
  dateOfBirth: '',
  pincode: '',
  state: '',
  city: '',
  address: ''
};

const MembershipForm = () => {
  const [isRazorpayLoading, setIsRazorpayLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      try {
        await loadRazorpay();
      } catch (err) {
        console.log(err);
        toast.error('Could not process payments at the moment');
      } finally {
        setIsRazorpayLoading(false);
      }
    })();
  }, []);

  const handleSubmit = useCallback(
    async values => {
      if (!window.Razorpay)
        return toast.error('Could not process payments at the moment');

      try {
        const razorpayOrder = await getMembershipCheckOut(
          user ? { ...user } : { ...values }
        );

        const options = {
          key: process.env.RAZORPAY_KEY_ID,
          amount: razorpayOrder.amount,
          currency: razorpayOrder.currency,
          name: 'Consumer Rights Organization',
          description: 'Membership fees',
          image: logoImg,
          order_id: razorpayOrder.id,
          theme: {
            color: '#3D62E8'
          },
          prefill: {
            name: user?.fullName || values.fullName,
            email: user?.email || values.email,
            contact: user?.phone || values.phone
          },
          handler() {
            toast.success('The payment was successful!');
            navigate('/me/dashboard');
          }
        };

        const paymentPortal = new window.Razorpay(options);
        paymentPortal.open();
      } catch (err) {
        toast.error(err.response?.data.message || 'Network Error');
      }
    },
    [user]
  );

  return !user ? (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={memberSchema}
      initialValues={initialValues}>
      {formik => (
        <form
          onSubmit={formik.handleSubmit}
          className='w-[40rem] mx-auto py-10 px-10 bg-gray-100 space-y-5 rounded-xl shadow-2xl phone:w-full'>
          <Heading variant='tertiary'>Become our member</Heading>
          <div className='grid grid-cols-2 gap-y-5 gap-x-10 tab-port:grid-cols-none'>
            <Input
              name='fullName'
              placeholder='Full name'
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fullName && formik.errors.fullName}
            />

            <Input
              name='email'
              placeholder='Email address'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />

            <Input
              name='phone'
              type='tel'
              placeholder='Phone number'
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
            />

            <Input
              name='password'
              type='password'
              placeholder='Password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />

            <Input
              name='confirmPassword'
              type='password'
              placeholder='confirm Password'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />

            <Input
              type='date'
              name='dateOfBirth'
              placeholder='date of birth'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            />

            <PincodeField
              name='pincode'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pincode}
              error={formik.touched.pincode && formik.errors.pincode}
              setFieldValue={formik.setFieldValue}
            />

            <Input
              name='state'
              placeholder='state'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              error={formik.touched.state && formik.errors.state}
              disabled
            />

            <Input
              name='city'
              placeholder='city'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={formik.touched.city && formik.errors.city}
              disabled
            />

            <Input
              name='address'
              placeholder='street address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              error={formik.touched.address && formik.errors.address}
            />
            <Button
              variant='filled'
              type='submit'
              className='col-span-full justify-self-center'
              isLoading={isRazorpayLoading || formik.isSubmitting}>
              Apply
            </Button>
          </div>
        </form>
      )}
    </Formik>
  ) : (
    <div className='flex justify-center'>
      <Button variant='filled' onClick={handleSubmit}>
        Apply for membership with existing account
      </Button>
    </div>
  );
};

export default MembershipForm;
