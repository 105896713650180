import React from 'react';

import Heading from '$ui/heading';

import TickIcon from '$assets/tick.svg';

const Success = () => (
  <div className='flex flex-col items-center space-y-8'>
    <TickIcon className='h-40 fill-primary' />
    <Heading>Thanks for becoming our member!</Heading>
  </div>
);

export default Success;
