import React from 'react';
import classNames from 'classnames';

import useMembershipForm from '$store/membershipFormContext';

const StepNumber = ({ number, active }) => (
  <div
    className={classNames(
      'flex justify-center items-center h-20 w-20 rounded-full text-2xl font-bold text-gray-500 tab-land:h-10 tab-land:w-10 phone:h-7 phone:w-7 phone:text-sm',
      active ? 'bg-green-200' : 'bg-gray-100'
    )}>
    {number}
  </div>
);

const StepLine = ({ text, active }) => (
  <div className='flex-1 text-center mt-2 uppercase tab-land:mt-0'>
    <p className='mb-2 tab-land:hidden'>{text}</p>
    <div
      className={classNames('h-1', active ? 'bg-green-200' : 'bg-white')}></div>
  </div>
);

const Step = ({ number, active, text }) => (
  <>
    <StepNumber active={active} number={number} />
    {text && <StepLine active={active} text={text} />}
  </>
);

const Stepper = ({ steps }) => {
  const { step: currentStep } = useMembershipForm();

  return (
    <div className='flex bg-primary text-white rounded-lg py-4 px-10 tab-land:items-center phone:px-3'>
      {steps.map((s, i) => (
        <Step key={i} number={i + 1} text={s} active={currentStep >= i + 1} />
      ))}
      <Step number={5} active={currentStep >= steps.length} />
    </div>
  );
};

export default Stepper;
