import React, { useMemo } from 'react';
import classNames from 'classnames';

import useMembershipForm from '$store/membershipFormContext';

import Button from '$ui/button';

import ArrowIcon from '$assets/arrowright.svg';
import CheckCircleIcon from '$assets/check-circle.svg';

const Package = ({ identifier, price, features }) => {
  const { data, setData, setStep } = useMembershipForm();
  const isSelectedPackage = useMemo(
    () => data.membershipType === identifier,
    [data, identifier]
  );

  return (
    <div
      className={classNames(
        'py-6 px-8 flex flex-col justify-between min-h-[30rem] rounded-lg transition-transform hover:scale-105',
        {
          'scale-110 bg-primary text-white shadow-2xl': isSelectedPackage,
          'bg-gray-100': !isSelectedPackage
        }
      )}>
      <div className='capitalize'>
        <h4>{identifier}</h4>
        <h3 className='text-2xl'>₹{price}</h3>
      </div>

      <ul className='capitalize space-y-2 py-5'>
        {features?.map((el, i) => (
          <li key={i} className='flex items-center  space-x-2 space-y-2'>
            <ArrowIcon
              className={classNames('h-3 w-3', {
                'fill-white': isSelectedPackage
              })}
            />
            <span>{el}</span>
          </li>
        ))}
      </ul>

      <Button
        variant={isSelectedPackage ? 'filled-plain' : 'filled'}
        onClick={() => {
          setData(prev => ({ ...prev, membershipType: identifier }));
          setStep(prev => prev + 1);
        }}>
        {isSelectedPackage ? (
          <CheckCircleIcon
            className={classNames(
              'mx-auto',
              isSelectedPackage ? 'fill-primary' : 'fill-white'
            )}
          />
        ) : (
          <>Choose</>
        )}
      </Button>
    </div>
  );
};

export default Package;
