import React from 'react';

import useMembershipForm from '$store/membershipFormContext';

import Heading from '$ui/heading';
import Button from '$ui/button';

const MembershipTerms = () => {
  const { setStep } = useMembershipForm();

  return (
    <div className='space-y-16'>
      <Heading>Conditions and agreement</Heading>

      <div className='space-y-6'>
        <p>
          To. <br />
          The President CONSUMER RIGHTS ORGANISATION
        </p>

        <p>
          Sir, <br />I want to take the Membership of the Consumer Rights
          Organisation for which I am submitting the Membership fee of Rs 200/-
          (rupees Two Hundred Only). Therefore, kindly register me as a Member
          of the Organization.
        </p>

        <p>
          I hereby declare that I am ready to abide by the rules and sub rules
          of the Consumer Rights Organisation in their Present / Current from or
          when they are updated from time to time.
        </p>

        <p>
          I promise to be loyal towards the mission of the organisation and
          would follow all those rules and regulations which are given to me by
          organisation to fulfill its mission.
        </p>
      </div>

      <div className='text-center space-x-10'>
        <Button
          variant='outlined-colored'
          onClick={() => setStep(prev => prev - 1)}>
          Back
        </Button>
        <Button variant='filled' onClick={() => setStep(prev => prev + 1)}>
          Agree
        </Button>
      </div>
    </div>
  );
};

export default MembershipTerms;
